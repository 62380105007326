<template>
  <div class="mobile-detail">
    <div class="mobile-detail-container" :style="buttons && buttons.length > 0 ? '' : 'margin-bottom:0'">
      <cube-scroll v-if="detail">
        <inputItem title="申请单号" :initialContent="detail.code" :disabled="true"></inputItem>
        <inputItem title="申请人员" :initialContent="detail.apply_name" :disabled="true"></inputItem>
        <inputItem title="申请时间" :initialContent="formatDate(detail.apply_time,'yyyy-MM-dd hh:mm')" :disabled="true"></inputItem>
        <inputItem title="系统名称" v-model="detail.system_name" :disabled="disabled" type="select" :options="systemOption"></inputItem>
        <inputItem title="到期日期" type="date" name="expire_time" :initialContent="formatDate(detail.expire_time)" :contentChanged="contentChanged" :disabled="disabled"></inputItem>
        <inputItem title="申请事由" v-model="detail.apply_memo" :disabled="disabled" type="textarea"></inputItem>
        <inputItem title="开通时间" :initialContent="formatDate(detail.open_time,'yyyy-MM-dd hh:mm')" :disabled="true" v-if="detail.state>3"></inputItem>
        <inputItem title="开通人员" :initialContent="detail.open_name" :disabled="true" v-if="detail.state>3"></inputItem>
        <inputItem title="开通内容" v-model="detail.open_memo" :disabled="detail.state<4" v-if="detail.state>3" type="textarea"></inputItem>
        <div class="mobile-detail-step" v-if="detail && detail.state > 1 && flows.flowLogs && flows.flowLogs.length>0">
          <Steps :current="flows.step" :status="flows.status" direction="vertical">
            <Step v-for="(item, index) in flows.flowLogs" :key="index" :title="item.userName" :content="formatDate(item.time, 'yyyy-MM-dd hh:mm') + (item.opinion ? ' - ' + item.opinion : '')"></Step>
          </Steps>
        </div>
      </cube-scroll>
    </div>
    <buttonGroup v-if="buttons && buttons.length > 0" :buttons="buttons" @button-click="buttonClick"></buttonGroup>
  </div>
</template>

<script>
import { GetAccountApply, AuditAccountApply, SaveAccountApply, CancelAccountApply, OpenAccountApply } from "@/api";
import { loadDetailAction, saveAction, auditAction, cancelAction, submitAction, loadDataOptionAction } from "@/utils/action.js";
import { formatDate } from "@/utils/format.js";

export default {
  data: function () {
    return {
      id: "",
      detail: null,
      flows: [],
      buttons: [],
      disabled: false,
      systemOption: [],
    };
  },
  methods: {
    formatDate: formatDate,
    contentChanged: function (index, value) {
      switch (index) {
        case "expire_time":
          this.detail.expire_time = value;
          break;
      }
    },
    buttonClick: function (title) {
      switch (title) {
        case "保存草稿":
          this.saveAccountApply(1);
          break;
        case "提交审核":
          this.saveAccountApply(2);
          break;
        case "审核通过":
          this.auditAccountApply(true, "同意");
          break;
        case "审核拒绝":
          this.auditAccountApply(false, "");
          break;
        case "撤回":
          this.cancelAccountApply();
          break;
        case "确认开通":
          this.openAccountApply();
          break;
      }
    },
    saveAccountApply: function (state) {
      this.detail.state = state;
      saveAction(this, SaveAccountApply, { model: this.detail }, () => { this.$router.go(-1); }, () => { this.detail.state = 1; });
    },
    auditAccountApply: function (pass, memo) {
      auditAction(this, AuditAccountApply, this.id, pass, memo);
    },
    cancelAccountApply: function () {
      cancelAction(this, CancelAccountApply, this.id);
    },
    openAccountApply: function () {
      submitAction(this, OpenAccountApply, { id: this.id, memo: this.detail.open_memo }, () => { this.$router.go(-1); }, null, true, "是否确定开通？");
    },
    dataRequest: function () {
      loadDetailAction(this, GetAccountApply, { id: this.id }, (data) => {
        this.detail = data.list[0].model;
        this.flows = data.list[0].flows;
        this.buttons = data.list[0].buttons;
        this.disabled = !this.buttons || this.buttons.indexOf("保存草稿") < 0;
      });
    },
  },
  mounted() {
    loadDataOptionAction(this, "预警监控系统", (data) => { this.systemOption = data; });

    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.dataRequest();
    }
  },
};
</script>